import React, { useLayoutEffect } from 'react';

type ModalProps = {
    title?: string;
    className?: string;
    onClose?: () => void;
    children: React.ReactNode;
}

export default function Modal({ title, children, className, onClose }: ModalProps) {
    useLayoutEffect(() => {
        // @ts-ignore
        document.body.classList.add('modal-open');
    });

    return (
        <div className={`modal ${className || ''}`}>
            <span className="close" onClick={() => onClose && onClose()}></span>
            {title && <div className="modal-title">
                <h2>{title}</h2>
            </div>}
            <div className="modal-content">
                {children}
            </div>
        </div>
    )
}