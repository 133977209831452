import React, { useState } from 'react';
import { useRownd } from '@rownd/react';

import wondrLogo from './assets/images/wondrgames-white.svg';

import rowndLogo from './assets/images/rownd-white.svg';
import './App.scss';
import SplashScreen from './components/SplashScreen';
import GamePlay from './components/GamePlay';

function App() {
  const { is_authenticated, requestSignIn, signOut } = useRownd();

  const [currentPage, setCurrentPage] = useState<'splash' | 'game'>('splash');

  return (
    <div className="app-container">
      <header className="main-header">
        <a href="https://wondr.games" className="logo"><img src={wondrLogo} alt="logo" /></a>
        <div className="header-menu">
          {is_authenticated &&
            <div className="header-buttons">
              <button className="sign-in-button" onClick={() => signOut()}>Sign out</button>
              <button className="profile-button" onClick={() => (window as any).rownd.user.manageAccount()}>Profile</button>
            </div>
          }
          {!is_authenticated && <button className="sign-in-button" onClick={() => requestSignIn()}>Sign in</button>}
        </div>
      </header>

      <main className="main-content">
        {currentPage === 'splash' && <SplashScreen setCurrentPage={setCurrentPage} />}
        {currentPage === 'game' && <GamePlay />}
      </main>

      <footer className="main-footer">
        <a href="https://rownd.io" rel="noreferrer" target="_blank">Powered by <img src={rowndLogo} className="rownd-logo" alt="rownd logo" /></a>
      </footer>
    </div>
  );
}

export default App;
