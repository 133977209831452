import React, { useState, useRef, useCallback, FormEvent, useEffect, useMemo } from "react";
import { useRownd } from "@rownd/react";
import useWindowSize from 'react-use/lib/useWindowSize';
import Confetti from 'react-confetti';
import ky from 'ky';
import phone from 'phone';
import Modal from './Modal';
import Leaderboard from './Leaderboard';
import rollOrFlopWhiteLogo from '../assets/images/rollorflop-white-blueshadow.svg';
import roll from '../assets/images/roll-button.svg'
import flop from '../assets/images/flop-button.svg'
import yepCheck from '../assets/images/yep-check.svg';
import nope from '../assets/images/nope.svg';

type Gif = {
    name: string;
    answer: 'roll' | 'flop';
    still_src: string;
    url: string;
}

function shuffle(array: any[]) {
    let currentIndex = array.length,  randomIndex;
  
    // While there remain elements to shuffle.
    while (currentIndex !== 0) {
  
      // Pick a remaining element.
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;
  
      // And swap it with the current element.
      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex], array[currentIndex]];
    }
  
    return array;
  }
  

export default function GamePlay() {
    const { width, height } = useWindowSize()
    const { user } = useRownd();
    const [answerWasCorrect, setAnswerWasCorrect] = useState(false);
    const [wasQuestionAnswered, setWasQuestionAnswered] = useState(false);
    const [isRequestingPhoneNumber, setIsRequestingPhoneNumber] = useState(false);
    const [isShowingLeaderboard, setIsShowingLeaderboard] = useState(false);
    const phoneNumberInput = useRef<HTMLInputElement>(null);
    const [gifs, setGifs] = useState<Gif[]>([]);
    const [currentGifIdx, setCurrentGifIdx] = useState<number>(0);

    const getMagicLink = useCallback(async function getMagicLink() {
        if (!user.data['phone_number']) {
            setIsRequestingPhoneNumber(true);
            return;
        }

        await ky.post('https://api.rownd.io/hub/auth/magic/app', {
            headers: {
                'Content-Type': 'application/json',
                'x-rownd-app-key': '95eca090-454a-4445-b1ec-2193474a8136'
            },
            json: {
                phone: user.data.phone_number
            }
        });
    }, [user.data]);

    async function fetchGifs() {
        let body: any = await ky.get('https://us-central1-roll-or-flop-45bfc.cloudfunctions.net/gifs').json();

        let localGifs = [];
        for (let item of body.gifs) {
            localGifs.push(item);
        }

        setGifs(shuffle(localGifs));
        setCurrentGifIdx(0);
    }

    function setStreak(streak: number) {
        // @ts-ignore
        rownd.user.setValue('streak', streak);

        if (streak > (user.data['longest_streak'] || 0)) {
            // @ts-ignore
            rownd.user.setValue('longest_streak', streak);
        }
    }

    function handleAnswer(answer: string) {
        // Check if answer is correct
        setWasQuestionAnswered(true);

        if (gifs[currentGifIdx].answer === answer) {
            // Increment score
            setStreak((user.data['streak'] || 0) + 1);
            setAnswerWasCorrect(true);
        } else {
            setStreak(0);
            setAnswerWasCorrect(false);
        }
    }

    function loadNextQuestion() {
        setAnswerWasCorrect(false);
        setWasQuestionAnswered(false);

        if (currentGifIdx < gifs.length - 1) {
            setCurrentGifIdx(currentGifIdx + 1);
        } else {
            setCurrentGifIdx(0);
        }
    }

    useEffect(() => {
        fetchGifs();
    }, []);

    const currentImage = wasQuestionAnswered ? gifs[currentGifIdx]?.url : gifs[currentGifIdx]?.still_src;

    return (
        <>
            <div className="game-area">
                {answerWasCorrect && wasQuestionAnswered && <Confetti width={width} height={height} recycle={false} numberOfPieces={500} />}
                <img src={rollOrFlopWhiteLogo} className="roll-or-flop-logo" alt="roll or flop logo" />
                <div className={`game-area-inner ${wasQuestionAnswered && !answerWasCorrect ? 'question-error' : ''}`}>
                    <span className="question-pill">Will it roll? Or will it flop?</span>
                    <h2 className="gif-title">{gifs[currentGifIdx]?.name}</h2>
                    <img src={currentImage} alt="will this roll or flop?" className="question-gif" />
                    {!wasQuestionAnswered && <div className="game-buttons">
                        <button onClick={() => handleAnswer('roll')}>
                            <img src={roll} alt="roll" />
                        </button>
                        <button className="action-primary" onClick={() => handleAnswer('flop')}>
                            <img src={flop} alt="flop" />
                        </button>
                    </div>}
                    {wasQuestionAnswered && <div className="game-buttons-answered">
                        {answerWasCorrect && <span className="answer answer-correct"><img src={yepCheck} alt="blue check" /> You got it!</span>}
                        {!answerWasCorrect && <span className="answer answer-incorrect"><img src={nope} alt="red X" /> Nope!</span>}
                        <button onClick={loadNextQuestion}>Next</button>
                    </div>}
                </div>
                <div className="game-area-bottom">
                    <div className="game-area-bottom-buttons">
                        <button onClick={getMagicLink}>Download the mobile app</button>
                        <button onClick={() => setIsShowingLeaderboard(true)}>View leaderboard</button>
                    </div>
                    <div className="game-area-bottom-stats">
                        <div className="game-stat">Streak: {user.data['streak'] || 0}</div>
                        <div className="game-stat best-streak">Best streak: {user.data['longest_streak'] || 0}</div>
                    </div>
                </div>
            </div>

            {isRequestingPhoneNumber && <Modal title="Enter your phone number for a text message to download the app" onClose={() => setIsRequestingPhoneNumber(false)}>
                <form onSubmit={(evt: FormEvent<HTMLFormElement>) => {
                    evt.preventDefault();

                    if (!phoneNumberInput.current?.value) {
                        return;
                    }

                    let phoneNumberObj = phone(phoneNumberInput.current?.value || '');
                    console.log(phoneNumberObj);
                    phoneNumberInput.current.value = phoneNumberObj.phoneNumber || phoneNumberInput.current.value;
                    // return;
                    // @ts-ignore
                    rownd.user.setValue('phone_number', phoneNumberObj.phoneNumber);
                    getMagicLink();
                    setIsRequestingPhoneNumber(false);
                }}>
                    <label>Phone number</label>
                    <input type="phone" className="text-field" placeholder="+1 (123) 456-7890" ref={phoneNumberInput} required />
                    <button className="form-submit" type="submit">Send me a download link</button>
                </form>
            </Modal>}

            {isShowingLeaderboard && <Leaderboard onClose={() => setIsShowingLeaderboard(false)} />}
        </>
    )
}