import React, { useEffect, useLayoutEffect } from 'react';
import ky from 'ky';
import Modal from './Modal';

type LeaderboardProps = {
    onClose?: () => void;
    // children: React.ReactNode;
}

type Leaderboard = {
    gamertag: string;
    longest_streak: number;
}

type LeaderboardResp = {
    leaderboard: Leaderboard[];
}

export default function Leaderboard({ onClose }: LeaderboardProps) {
    const [leaderboard, setLeaderboard] = React.useState<Leaderboard[]>([]);

    useEffect(() => {
        (async () => {
            let body: LeaderboardResp = await ky.get('https://us-central1-roll-or-flop-45bfc.cloudfunctions.net/leaderboard').json();

            setLeaderboard(body.leaderboard);
            console.log(body);
        })();
    }, []);

    return (
        <Modal onClose={onClose} className="leaderboard">
            <h1>Leaderboard</h1>

            <table>
                <thead>
                    <tr>
                        <th>Rank</th>
                        <th>Gamertag</th>
                        <th>Streak</th>
                    </tr>
                </thead>

                <tbody>
                    {leaderboard.map((leaderboardItem, idx) => (
                        <tr key={idx}>
                            <td>{idx + 1}</td>
                            <td>{leaderboardItem.gamertag}</td>
                            <td>{leaderboardItem.longest_streak}</td>
                        </tr>   
                    ))}
                </tbody>
            </table>
        </Modal>
    )
}