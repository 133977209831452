import React from 'react';
import { useRownd } from '@rownd/react';
import rollOrFlopColorLogo from '../assets/images/composite-landing.svg';

type SplashScreenProps = {
    setCurrentPage: (page: 'splash' | 'game') => void;
}

export default function SplashScreen({ setCurrentPage }: SplashScreenProps) {
    const { is_authenticated, requestSignIn } = useRownd();

    function signIn() {
        // Listen for a sign-in to occur after requesting a sign-in
        // @ts-ignore
        rownd.events.addEventListener('auth', (event: CustomEvent) => {
            setCurrentPage('game');
        }, { once: true });

        requestSignIn();
    }

    function startPlay() {
        setCurrentPage('game');
    }

    return (
        <div className="splash-screen">
          <img src={rollOrFlopColorLogo} className="splash-logo" alt="Roll or Flop logo" />
          <button className="action-primary" onClick={startPlay}>Play now</button>
          {!is_authenticated && <button className="action-link" onClick={signIn}>Sign in to existing session</button>}
        </div>
    )
}